.container {
  padding-top: 20px;
  position: relative;
  background-color: #ececec;
  height: 100vh;

  .topBar {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    color: #ffffff;
    min-height: 55px;
    padding: 0 20px;

    .backArrow {
      position: absolute;
      left: 0;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
    }
  }

  .headingWrapper {
    display: flex;
    max-width: 415px;
    min-height: 35px;
    align-items: center;
    justify-content: space-between;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;

      svg {
        color: #d83c95;
      }
    }
  }

  .choiceWrapper {
    text-align: center;
    padding: 20px 0;

    @media screen and (max-width: 640px) {
      text-align: left;
      padding-left: 20px;
    }
  }

  .valoresWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    gap: 10px;
    border-radius: 10px;

    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: center;
    }

    .oportunidadesCard {
      background-color: #ffffff;
      max-width: 160px;
      width: 100%;
      margin-right: 15px;
      border-radius: 10px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 640px) {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }

  .moreValuesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 10px;

    input {
      background-color: transparent;
      border-bottom: 1px solid #b6b6b6;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      width: 80px;
      text-align: center;
      outline: none;
      margin-left: 10px;

      &::placeholder {
        color: #000000;
      }
    }
  }

  .buttonWrapper {
    max-width: 150px;
    margin: 0 auto;
    line-height: 22px;
    margin-top: 20px;
  }
}
