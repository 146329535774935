.containerContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  
  &.overflowHidden {
    overflow: hidden;
  }
}