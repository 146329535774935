.container {
    .infos {
        display: flex;

        > div {
            &:first-child {
                margin-right: 10px;
            }
        }
    }

    a {
        display: flex;
        justify-content: flex-end;
        > div {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            background-color: #d22688;
            width: 40px;
            height: 40px;
            color: #fff;
        }
    }
}
