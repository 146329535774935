html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #FFFFFF;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #FFFFFF;
  scroll-behavior: smooth;

  h1 {
    margin: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
