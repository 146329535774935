.container {
  .moreInfos {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1.5px solid #dcdcdc;

    
    @media screen and (max-width: 768px) {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 80px;
        }
    }

    > div {
        display: flex;

        h1 {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    .contentWrapper {
        display: flex;
        flex-direction: column;
        
        > a {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 5px;
            border-bottom: 1px solid #dcdcdc;

            &:first-child {
                border-top: 1px solid #dcdcdc;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            margin-left: 16px;
            background: #ffd6ee;

            svg {
                color: #d83c95;
                font-size: 18px;
            }
        }
    }
}
}