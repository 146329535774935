.container {
  padding-top: 20px;
  position: relative;

  .topBar {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    color: #ffffff;
    min-height: 55px;
    padding: 0 20px;

    .backArrow {
      position: absolute;
      left: 0;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
    }
  }

  .headingWrapper {
    display: flex;
    max-width: 415px;
    min-height: 35px;
    align-items: center;
    justify-content: space-between;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;

      svg {
        color: #d83c95;
      }
    }
  }

  .border {
    .tabsBorder {
      background-color: #d83c95;
    }

    .tabsRoot {
      > div {
        div {
          display: flex;
          justify-content: space-around;
        }
      }

      .testeTabs {
        width: 50%;
        font-weight: 700;
        color: #000000 !important;
      }
    }
  }

  .teste {
    display: flex;
    justify-content: center;

    img {
      width: 180px;
      height: auto;
      margin: 0 auto;
    }

    .tabText {
      margin-top: 30px;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        text-align: center;
        max-width: 200px;
      }
    }
  }
}
