.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 100vh;

  svg {
    font-size: 35px;
    color: #d83c95;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .buttonWrapper {
    width: 200px;
    padding-top: 20px;
    margin: 0 auto;
  }
}
