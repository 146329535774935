.container {
    padding-top: 20px;
    position: relative;

    .topBar {
        position: fixed;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: #ffffff;
        min-height: 55px;
        padding: 0 20px;

        .backArrow {
            position: absolute;
            left: 0;
        }

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 16px;
        }
    }

    .headingWrapper {
        display: flex;
        max-width: 415px;
        min-height: 35px;
        align-items: center;
        justify-content: space-between;

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 16px;

            svg {
                color: #d83c95;
            }
        }
    }

    .doubtWrapper {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 16px;
        max-width: 500px;
        margin: 0 auto;

        > div {
            margin-bottom: 25px;
        }

        .contentWrapper {
            > a {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 14px;
                border-bottom: 2px solid #dcdcdc;
            }

            .arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                width: 25px;
                height: 25px;
                margin-left: 16px;
                background: #ffd6ee;

                svg {
                    color: #d83c95;
                }
            }
        }
    }
}
